<template>
    <client-page>
        <sub-visual
        :subTabNone="$route.params._campaign"
        sh="캠페인"
        tabActive="비대면 봉사활동 (단체)"
        bg="/images/sub/visual/sv4.jpg">
        </sub-visual>
        
        <section class="section">
            <v-container>
                <campaign-list v-if="!$route.params._campaign && $route.query.mode !== 'input'" code="support"/>
                <campaign-write v-else-if="$route.query.mode === 'input'"/>
                <campaign-view v-else/>
            </v-container>
        </section>
        
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import CampaignView from "@/components/client/support/campaign-view.vue"
import CampaignList from "@/components/client/support/campaign-list.vue"
import CampaignWrite from '@/components/client/support/campaign-write.vue';

export default {
    components: {
        ClientPage,
        SubVisual,
        CampaignView,
        CampaignList,
        CampaignWrite
    },
    data() {
        return {
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        async init(){
        }
    },
    watch:{
        code(){
        }
    }
}
</script>

<style>

</style>