var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "subTabNone": _vm.$route.params._campaign,
      "sh": "캠페인",
      "tabActive": "비대면 봉사활동 (단체)",
      "bg": "/images/sub/visual/sv4.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [!_vm.$route.params._campaign && _vm.$route.query.mode !== 'input' ? _c('campaign-list', {
    attrs: {
      "code": "support"
    }
  }) : _vm.$route.query.mode === 'input' ? _c('campaign-write') : _c('campaign-view')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }